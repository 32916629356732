.login-form {
    padding: 0 30%;

    // https://getbootstrap.com/docs/4.0/layout/overview/#responsive-breakpoints
    @media only screen and (max-width: 768px) {
        padding: 0 5%;
    }

    @media screen and (max-width: 992px) {
        padding: 0 15%;
    }
}